
import "./App.css"
import React from 'react';
import { useState } from 'react';
import axios from "axios";
import FileBase64 from 'react-file-base64';

const ENDPOINT_URL = "http://ab949aa34e4194020aa108f563aa52db-1310313925.ap-south-1.elb.amazonaws.com:8000/count"

const App = () => {

  const [imgBase64,setBase64] = useState("");
  const [imgOutBase64,setOutBase64] = useState("");
  const [objectsDetected,setDetected] = useState(0);

  function getFiles(file){
    console.log("BASE64 : ",file["base64"]); 
    setBase64(file["base64"])
  }

  function countObjects(){
    
    if(imgBase64==""){
      console.log("No Image Uploaded yet !");
      return;
    }

    // Send POST request
    console.log("POST request sending now...")
    axios
      .post(ENDPOINT_URL, {imagebase64: imgBase64})
      .then((response) => {
        console.log("RESULT : ", response.data)
        setOutBase64("data:image/jpeg;base64,"+response.data["IMAGE_DATA"])
        setDetected(response.data["NUM_OBJECTS"])
      });

  }

  return (
    <div className="App">
       <div className="upload">
       <FileBase64 multiple={false} onDone={getFiles}/>
       </div>
      <div> 
      <img className="input-image" src={imgBase64}/>
      <img className="output-image" src={imgOutBase64}/>
      </div>
      <button onClick={countObjects}> COUNT </button>
      <h3 className="num-objects">  No. of Objects Detected : {objectsDetected} </h3>
    </div>
  );
};

export default App;

